.card {
  border-radius: 10px;
  border: none;
  background-color: #fff;
  min-width: 20em;
}

.cardParent {
  border: 5px solid #FFF;

}

.stats {

  background: #f2f5f8 !important;

  color: #000 !important;
}

.articles {
  font-size: 10px;
  color: #a1aab9;
}

.number1 {
  font-weight: 500;
}

.followers {
  font-size: 10px;
  color: #a1aab9;

}

.number2 {
  font-weight: 500;
}

.rating {
  font-size: 10px;
  color: #a1aab9;
}

.number3 {
  font-weight: 500;
}